<script setup>
import { useCurrencyStore } from '~/stores/currency';
import { GA_ACCOUNTS } from '~/config/analytics';

const runtimeConfig = useRuntimeConfig();
const { locale } = useI18n();
const { useUrl } = useUrls();
const currencyStore = useCurrencyStore();
const testingStore = useTestingStore();
const sustainabilityStore = useSustainabilityStore();
const envData = runtimeConfig?.public;
const isGoogleTagManagerEnabled = testingStore.hasEnabledFeature('web_googleTagManager');

const COOKIESPOLICYURL = useUrl.getCookiePolicyUrl();
const SECURITYPRIVACYURL = useUrl.getSecurityPrivacyUrl();

const actualLanguage = locale?.value;

// @TODO: For some weird reason, the trustArc banner is being rendered twice on DOM, but script is only called once
const trustArcScript = [
  {
    async: true,
    crossorigin: 'anonymous',
    fetchpriority: 'high',
    src:
      'https://consent.trustarc.com/notice?domain=hostelworld.com'
      + '&c=teconsent'
      + '&gtm=1'
      + '&js=nj'
      + '&noticeType=bb'
      + `&language=${actualLanguage}`
      + '&language=true'
      + '&text=true'
      + `&cookieLink=${encodeURIComponent(`${COOKIESPOLICYURL}`)}&privacypolicylink=${encodeURIComponent(
        `${SECURITYPRIVACYURL}`,
      )}`,
  },
];

const googleHpa = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtag',
      defer: true,
      crossorigin: 'anonymous',
      src: `https://www.googletagmanager.com/gtag/js?id=${GA_ACCOUNTS[0].ID}`,
    },
  ]
  : [];

const gtm = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtm',
      defer: true,
      crossorigin: 'anonymous',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${envData.GTM_ID}');`,
    },
  ]
  : [];

const headerScripts = [...trustArcScript, ...googleHpa, ...gtm];

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  script: headerScripts,
}));
</script>

<template>
  <div class="container">
    <main>
      <slot />
    </main>

    <ClientOnly>
      <LazyCommonHwCurrencyPicker v-if="currencyStore.isCurrencyPickerOpen" />
      <LazyCommonHwSustainabilityPopup v-if="sustainabilityStore.isSustainabilityPopupOpen" />
    </ClientOnly>

    <CommonHwFooter />

    <ClientOnly>
      <LazyCommonHwCookieBar />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.container {
  background-color: $wds-color-black;
  color: $wds-color-white;
}
</style>
